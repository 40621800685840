import { Observable } from "./observable";

type Getter<T> = () => Promise<T>;

export class Cache<T, TKey extends string | number = number> {
    private _cache: {
        [key: number]: Observable<T | undefined> | undefined;
        [key: string]: Observable<T | undefined> | undefined;
    } = {};
    private _getting: {
        [id: number]: true;
        [id: string]: true;
    } = {};

    public get(id: TKey, getter: Getter<T>): Observable<T | undefined> {
        let o: Observable<T | undefined> | undefined = this._cache[id];
        if (!o) {
            o = new Observable<T | undefined>(undefined);
            this._cache[id] = o;
        }
        const observable = o;

        if (observable.getValue() === undefined && !this._getting[id]) {
            this._getting[id] = true;
            getter().then(v => {
                delete this._getting[id];
                observable.setValue(v);
            });
        }
        return observable;
    }

    /**
     * Returns the Observable for id if it exists, but does not fetch the item if it does not.
     */
    public getOptional(id: TKey): Observable<T | undefined> | undefined {
        return this._cache[id];
    }

    public set(id: TKey, val: T): Observable<T | undefined> {
        const o: Observable<T | undefined> | undefined = this._cache[id];
        if (o) {
            o.setValue(val);
            return o;
        } else {
            return (this._cache[id] = new Observable<T | undefined>(val));
        }
    }
}

// export class CachedValue<T> {
//     private _cache = new Cache<T>();
//     public get(getter: Getter<T>): Observable<T | undefined> {
//         return this._cache.get(0, getter);
//     }

//     public getOptional(): Observable<T | undefined> | undefined {
//         return this._cache.getOptional(0);
//     }

//     public set(val: T): void {
//         this._cache.set(0, val);
//     }
// }
