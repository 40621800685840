import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorCollection } from "../api/common";
import { joinTeamFromInviteCode } from "../api/team";
import { MeAlert, MessageContext } from "../components/mealert";
import { MeNavbar } from "../components/menavbar";

export function JoinTeam(): JSX.Element {
    const { inviteCode } = useParams<{ inviteCode: string }>();
    const [errorCollection] = useState(() => new ErrorCollection());
    const navigate = useNavigate();
    const messageContext = useContext(MessageContext);

    useEffect(() => {
        if (!inviteCode) {
            errorCollection.push("No invite code provided.");
        }
        else {
            joinTeamFromInviteCode(inviteCode).then(res => {
                if (Array.isArray(res)) {
                    errorCollection.push(...res);
                } else {
                    const target = `/team/${res.team.teamId}`;
                    if (res.newlyJoined) {
                        messageContext.setMessage({ text: `You have joined the team ${res.team.name}.`, location: target });
                    }
                    navigate(target);
                }
            });
        }
    }, [inviteCode, errorCollection, navigate, messageContext]);
    return (
        <>
            <MeNavbar buttons={[]} />
            <Row>
                <Col>
                    <MeAlert errorCollection={errorCollection} />
                </Col>
            </Row>
        </>
    );
}
