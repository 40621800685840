import { Moment } from "moment";
import React, { FunctionComponent, useLayoutEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import DateTime from "react-datetime";
import { Link, useParams } from "react-router-dom";

import { ErrorCollection } from "../api/common";
import { MoraleEvent, NewMoraleEvent, addEvent, refreshEvent } from "../api/event";
import { Team, getTeam } from "../api/team";
import { Observable } from "../common/observable";
import { useRenderLog } from "../common/util";
import { MeAlert } from "../components/mealert";
import { MeNavbar } from "../components/menavbar";
import { Observer } from "../components/observer";
import { RoundDialog } from "./round";

export function Event(): JSX.Element {
    const { eventId: eventIdStr } = useParams<{ eventId: string }>();
    const eventId = Number(eventIdStr);
    const [errorCollection] = useState(() => new ErrorCollection());
    const [showAddRoundModal, setShowAddRoundModal] = useState(false);

    return (
        <>
            <EventObserver eventId={eventId} errorCollection={errorCollection}>
                {({ event, team }) => (
                    <>
                        <MeNavbar
                            breadcrumbs={[
                                team && { text: team.name, href: `/team/${team.teamId}` },
                                event && { text: event.name, active: true },
                            ]}
                            buttons={[
                                event &&
                                    team &&
                                    team.isAdmin && { text: "Add Round", onClick: () => setShowAddRoundModal(true) },
                            ]}
                        />
                        <Row>
                            <Col>
                                <MeAlert errorCollection={errorCollection} />
                                {event === undefined ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <>
                                        <Table className="mt-3">
                                            <tbody>
                                                <tr>
                                                    <th>Round</th>
                                                    <th>Closing Time</th>
                                                    <th>Votes Per User</th>
                                                    <th>Users Can Add Options</th>
                                                </tr>
                                                {event.rounds.map((r, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <Link to={`/event/${event.eventId}/round/${i}`}>
                                                                {r.name}
                                                            </Link>
                                                        </td>
                                                        <td>{new Date(r.closeTime).toLocaleString()}</td>
                                                        <td>{r.votesPerUser}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                title="users can add options"
                                                                checked={r.usersCanAddOptions}
                                                                readOnly
                                                                onClick={() => false}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                )}
                            </Col>
                        </Row>
                        {showAddRoundModal && event && (
                            <RoundDialog event={event} onDismiss={() => setShowAddRoundModal(false)} />
                        )}
                    </>
                )}
            </EventObserver>
        </>
    );
}

const undefinedTeamObserver = new Observable<Team | undefined>(undefined);
export interface EventObserverProps {
    eventId: number;
    errorCollection: ErrorCollection;
    children: FunctionComponent<{
        event: MoraleEvent | undefined;
        team: Team | undefined;
        errorCollection: ErrorCollection;
    }>;
}
export function EventObserver({ eventId, errorCollection, children }: EventObserverProps): JSX.Element {
    useRenderLog("EventObserver");
    const [eventObservable] = useState(() => refreshEvent(eventId, errorCollection));

    return (
        <Observer observable={eventObservable}>
            {event => (
                <Observer observable={getTeamObservable(event?.teamId)}>
                    {team => React.createElement(children, { event, team, errorCollection })}
                </Observer>
            )}
        </Observer>
    );

    function getTeamObservable(teamId?: number): Observable<Team | undefined> {
        if (teamId !== undefined) {
            return getTeam(teamId, errorCollection);
        } else {
            return undefinedTeamObserver;
        }
    }
}

export function EventDialog({ team, onDismiss }: { team: Team; onDismiss: (added: boolean) => void }): JSX.Element {
    const [busy, setBusy] = useState(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const nameInput = useRef<typeof FormControl & HTMLInputElement>(null);
    const descriptionInput = useRef<typeof FormControl & HTMLTextAreaElement>(null);
    const [when, setWhen] = useState(0);
    useLayoutEffect(() => void nameInput.current?.focus(), []);

    const valid = !!(!busy && nameInput.current?.value && when > Date.now());

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss(false)}>
            <Modal.Header>
                <Modal.Title>Add Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors && (
                    <Alert variant="danger">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Alert>
                )}
                <Form
                    onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            onSaveClick();
                        }
                    }}
                >
                    <Form.Group controlId="eventName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" maxLength={50} ref={nameInput} />
                    </Form.Group>
                    <Form.Group controlId="eventDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" maxLength={4000} ref={descriptionInput} />
                    </Form.Group>
                    <Form.Group controlId="eventDateTime">
                        <Form.Label>When</Form.Label>
                        <DateTime
                            isValidDate={(d: Moment) => d.isSameOrAfter(Date.now())}
                            timeFormat={false}
                            onChange={v => setWhen(typeof v === "string" ? 0 : v.valueOf())}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!valid} onClick={onSaveClick}>
                    Add Event
                </Button>
                <Button variant="secondary" disabled={busy} onClick={(): void => onDismiss(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onSaveClick(): Promise<void> {
        setBusy(true);
        setErrors(null);
        const event: NewMoraleEvent = {
            teamId: team.teamId,
            name: nameInput.current!.value,
            description: descriptionInput.current!.value,
            when,
        };
        const errors = await addEvent(event);
        setBusy(false);
        if (errors) {
            setErrors(errors);
        } else {
            onDismiss(true);
        }
    }
}
