import { Cache } from "../common/cache";
import { Observable } from "../common/observable";
import { ErrorCollection, deleteWithValidation, postWithValidation, simpleGet } from "./common";
import { MoraleEvent } from "./event";
import { User, getContext } from "./user";

export interface SlimTeam {
    teamId: number;
    name: string;
}

// @todo: none of this
export interface Team {
    teamId: number;
    name: string;
    isAdmin: boolean;
    isMember: boolean;
    admins: string[];
    inviteCode: string;
}

export interface NewTeam {
    name: string;
    admins: string[];
}

export interface TeamMember {
    user: User;
    isAdmin: boolean;
}

const teamCache = new Cache<Team | undefined>();

export function getTeam(teamId: number, errorCollection: ErrorCollection): Observable<Team | undefined> {
    const o = teamCache.get(teamId, () => {
        return fetchTeam(teamId).catch(ex => void errorCollection.push(ex.message as string));
    });

    return o;
}

export function refreshTeam(teamId: number, errorCollection: ErrorCollection): Observable<Team | undefined> {
    const o = teamCache.getOptional(teamId);
    if (o) {
        o.setValue(undefined);
    }
    return getTeam(teamId, errorCollection);
}

export async function getUserTeams(): Promise<Team[]> {
    return simpleGet("/api/user/teams");
}

export async function getAllTeams(): Promise<{ teamId: number; name: string }[]> {
    return simpleGet("/api/team/all");
}

export async function addTeam(team: NewTeam): Promise<Team | string[]> {
    return (await postWithValidation<Team>("/api/team", team)) as Team | string[];
}

export async function joinTeam(teamId: number): Promise<boolean> {
    try {
        const response = await fetch(`/api/team/${teamId}/join`, {
            method: "POST",
        });
        const teamObservable = teamCache.getOptional(teamId);
        const team = teamObservable?.getValue();
        if (response.ok && teamObservable && team) {
            teamObservable.setValue({
                ...team,
                isAdmin: !!(await getContext())?.currentUser?.isSuperAdmin,
                isMember: true,
            });
        }
        return response.ok;
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
        return false;
    }
}

export async function leaveTeam(teamId: number): Promise<boolean> {
    try {
        const response = await fetch(`/api/team/${teamId}/leave`, {
            method: "POST",
        });
        const teamObservable = teamCache.getOptional(teamId);
        const team = teamObservable?.getValue();
        if (response.ok && teamObservable && team) {
            teamObservable.setValue({
                ...team,
                isAdmin: !!(await getContext())?.currentUser?.isSuperAdmin,
                isMember: false,
            });
        }
        return response.ok;
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
        return false;
    }
}

async function fetchTeam(teamId: number): Promise<Team> {
    return simpleGet(`/api/team/${teamId}`);
}

export async function getTeamMembers(teamId: number): Promise<TeamMember[]> {
    return simpleGet(`/api/team/${teamId}/members`);
}

export async function getTeamEvents(teamId: number): Promise<MoraleEvent[]> {
    return simpleGet(`/api/team/${teamId}/events`);
}

export async function addAdmin(teamId: number, username: string): Promise<undefined | string[]> {
    return postWithValidation(`/api/team/${teamId}/admins`, { username });
}

export async function removeAdmin(teamId: number, username: string): Promise<undefined | string[]> {
    return deleteWithValidation(`/api/team/${teamId}/admins`, { username });
}

export async function updateInviteCode(teamId: number): Promise<undefined | string[]> {
    const result = await postWithValidation<Team>(`/api/team/${teamId}/updateInviteCode`, {});
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        teamCache.set(result.teamId, result);
    }
}

export async function clearInviteCode(teamId: number): Promise<undefined | string[]> {
    const result = await postWithValidation<Team>(`/api/team/${teamId}/clearInviteCode`, {});
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        teamCache.set(result.teamId, result);
    }
}

type JoinTeamResponse = { team: Team; newlyJoined: boolean };
export async function joinTeamFromInviteCode(inviteCode: string): Promise<JoinTeamResponse | string[]> {
    return (await postWithValidation<JoinTeamResponse>(`/api/team/join/${inviteCode}`, {})) as
        | JoinTeamResponse
        | string[];
}

export async function removeMember(teamId: number, username: string): Promise<undefined | string[]> {
    return deleteWithValidation(`/api/team/${teamId}/members`, { username });
}
