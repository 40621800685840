import { delay } from "./util";

export type Observed<T> = T extends Observable<infer U> ? U : T;

type Listener<T> = (val: T) => void;

export interface IObservable<T> {
    subscribe(listener: Listener<T>): () => void;
    getValue(): T;
}

export interface IWritableObservable<T> extends IObservable<T> {
    setValue(v: T): void;
}

class ObservableBase<T> {
    protected _listeners = [] as Listener<T>[];
    private _notifying = false;
    private _toUnsubscribe = [] as Listener<T>[];

    protected notify(val: T): void {
        if (this._notifying) {
            throw new Error("Observable does not support recursive updates.");
        }
        this._notifying = true;
        this._listeners.forEach(l => l(val));
        this._notifying = false;
        this._toUnsubscribe.forEach(l => this.unsubscribe(l));
        this._toUnsubscribe.splice(0);
    }

    public subscribe(listener: Listener<T>): () => void {
        this._listeners.push(listener);
        return () => {
            this.unsubscribe(listener);
        };
    }

    private unsubscribe(listener: Listener<T>): void {
        if (!this._notifying) {
            const index = this._listeners.indexOf(listener);
            if (index >= 0) {
                this._listeners.splice(index, 1);
            }
        } else {
            this._toUnsubscribe.push(listener);
        }
    }
}

export class Observable<T> extends ObservableBase<T> implements IWritableObservable<T> {
    private _value: T;

    public constructor(initialValue: T) {
        super();
        this._value = initialValue;
    }

    public getValue(): T {
        return this._value;
    }
    public setValue(val: T): void {
        if (this._value !== val) {
            this._value = val;
            this.notify(val);
        }
    }
}

export class ObservableArray<T> extends ObservableBase<T[]> implements IWritableObservable<T[]> {
    private _value: T[];

    public constructor(initialValue: T[]) {
        super();
        this._value = initialValue;
    }

    public getValue(): T[] {
        return this._value;
    }

    public setValue(val: T[]): void {
        this._value = [...val];
        this.notify(this._value);
    }

    public push(...val: T[]): void {
        this._value = [...this._value, ...val];
        this.notify(this._value);
    }

    public clear(): void {
        this._value = [];
        this.notify(this._value);
    }
}

export class LoadingObservableArray<T> extends ObservableArray<T> {
    public loading = true;
}

/**
 * Observable that delays updating for certain values.
 */
export class DelayedObservable<T> extends ObservableBase<T> implements IWritableObservable<T> {
    private _value: T;
    private _lastSetTime: number;
    private _getDelay: (v: T) => number;

    public constructor(initialValue: T, getDelay: (v: T) => number) {
        super();
        this._value = initialValue;
        this._lastSetTime = 0;
        this._getDelay = getDelay;
    }

    public getValue(): T {
        return this._value;
    }
    public setValue(val: T): void {
        const setTime = Date.now();
        const delayMs = this._getDelay(val);

        delay(delayMs).then(() => {
            if (setTime > this._lastSetTime) {
                this._lastSetTime = Date.now();
                if (this._value !== val) {
                    this._value = val;
                    this.notify(val);
                }
            }
        });
    }
}
