import * as React from "react";
import BreadCrumb from "react-bootstrap/Breadcrumb";
import BreadcrumbItem, { BreadcrumbItemProps } from "react-bootstrap/BreadcrumbItem";
import Button, { ButtonProps } from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate } from "react-router";

import * as User from "../api/user";
import { MeContext } from "../common/mecontext";
import { Falsey, SimpleMouseEvent, clickToReactRouter } from "../common/util";
import { Avatar } from "./avatar";

export interface MeNavbarBreadcrumbProps {
    text: string;
    href?: string;
    active?: boolean;
}

interface MeNavbarButton {
    type?: "button";
    text: string;
    variant?: ButtonProps["variant"];
    onClick: () => void;
}

interface MeNavbarDropdown {
    type: "dropdown";
    text: string;
    items: {
        text: string;
        disabled?: boolean;
        onClick: () => void;
    }[];
}
export type MeNavbarItem = MeNavbarButton | MeNavbarDropdown;

export interface MeNavbarProps {
    breadcrumbs?: (MeNavbarBreadcrumbProps | Falsey)[];
    buttons?: (MeNavbarItem | Falsey)[];
}
export const MeNavbar = ({ breadcrumbs, buttons }: MeNavbarProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <Row>
                <Col>
                    <MeContext.Consumer>
                        {(context): JSX.Element => (
                            <Navbar className="p-0 mb-3 align-items-baseline" collapseOnSelect expand="md">
                                <Navbar.Brand
                                    href="/"
                                    onClick={(e: React.MouseEvent) => clickToReactRouter(e, navigate)}
                                >
                                    morale.events
                                </Navbar.Brand>
                                <Navbar.Toggle className="me-auto" aria-controls="navbar-collapse" />
                                <Navbar.Collapse id="navbar-collapse">
                                    <Nav className="me-auto">
                                        {context.currentUser.isSuperAdmin && (
                                            <Nav.Link
                                                active={location.pathname === "/user/list"}
                                                href="/user/list"
                                                onClick={(e: SimpleMouseEvent) => clickToReactRouter(e, navigate)}
                                            >
                                                Users
                                            </Nav.Link>
                                        )}
                                    </Nav>
                                </Navbar.Collapse>
                                <NavDropdown
                                    id="avatar-dropdown"
                                    align="end"
                                    title={<Avatar user={context.currentUser} size={30} alt="User menu" />}
                                >
                                    <NavDropdown.Item
                                        href={`https://github.com/settings/connections/applications/${context.clientId}`}
                                        target="_blank"
                                    >
                                        Review GitHub Access
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={async (): Promise<void> => {
                                            (await User.logout()) && (window.location.href = "/");
                                        }}
                                    >
                                        Log Out
                                    </NavDropdown.Item>
                                    {context.currentUser.isSuperAdmin && (
                                        <>
                                            <NavDropdown.Item disabled>{context.version}</NavDropdown.Item>
                                            <NavDropdown.Item disabled>{context.sha?.substring(0, 6)}</NavDropdown.Item>
                                        </>
                                    )}
                                </NavDropdown>
                            </Navbar>
                        )}
                    </MeContext.Consumer>
                </Col>
            </Row>
            <Row className="mb-2 align-items-baseline justify-content-between">
                <Col md="auto">
                    {breadcrumbs && (
                        <BreadCrumb listProps={{ className: "mb-0" }}>
                            {(breadcrumbs.filter(bc => !!bc) as MeNavbarBreadcrumbProps[]).map((bc, i) => (
                                <MeBreadCrumbItem key={i} href={bc.href} active={bc.active}>
                                    {bc.text}
                                </MeBreadCrumbItem>
                            ))}
                        </BreadCrumb>
                    )}
                </Col>
                <Col md="auto" className="d-flex justify-content-end">
                    <ButtonToolbar>
                        {buttons &&
                            (buttons.filter(b => !!b) as MeNavbarItem[]).map((b, i) => {
                                const className = "ms-2";
                                if (b.type === "dropdown") {
                                    return (
                                        <Dropdown key={i} className={className}>
                                            <Dropdown.Toggle>{b.text}</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {b.items.map((item, j) => (
                                                    <Dropdown.Item
                                                        key={j}
                                                        onClick={item.onClick}
                                                        disabled={item.disabled}
                                                    >
                                                        {item.text}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                } else {
                                    return (
                                        <Button key={i} className={className} variant={b.variant} onClick={b.onClick}>
                                            {b.text}
                                        </Button>
                                    );
                                }
                            })}
                    </ButtonToolbar>
                </Col>
            </Row>
        </>
    );
};

function MeBreadCrumbItem(props: BreadcrumbItemProps & { children?: React.ReactNode }): JSX.Element {
    const navigate = useNavigate();
    return (
        <BreadcrumbItem {...props} onClick={(e: React.MouseEvent) => clickToReactRouter(e, navigate)}></BreadcrumbItem>
    );
}
