import { ErrorCollection, patchWithValidation, postWithValidation, requestWithValidation, simpleGet } from "./common";
import { Cache } from "../common/cache";
import { IObservable, IWritableObservable } from "../common/observable";
import { delay, rateLimit } from "../common/util";

export interface MoraleEvent {
    eventId: number;
    teamId: number;
    name: string;
    description: string;
    when: number;
    rounds: Round[];
}

export interface Round {
    name: string;
    description: string;
    closeTime: number;
    votesPerUser: number;
    usersCanAddOptions: boolean;
    hideResultsUntilVoted?: boolean;
    abstentions: string[];
    options: Option[];
}

export interface Option {
    name: string;
    votes: string[];
    comments: Comment[];
}

export interface Comment {
    username: string;
    when: number;
    comment: string;
}

export interface NewMoraleEvent extends Omit<MoraleEvent, "eventId" | "rounds"> {}
export type EditRound = Omit<Round, "abstentions" | "options"> & Partial<Pick<Round, "options">>;
export interface NewOption extends Omit<Option, "votes" | "comments"> {}

const eventCache = new Cache<MoraleEvent | undefined>();

export function getEvent(eventId: number, errorCollection: ErrorCollection): IObservable<MoraleEvent | undefined> {
    const o = eventCache.get(eventId, () => {
        return fetchEvent(eventId).catch(ex => void errorCollection.push(ex.message as string));
    });

    return o;
}

export function refreshEvent(eventId: number, errorCollection: ErrorCollection): IObservable<MoraleEvent | undefined> {
    const o = eventCache.getOptional(eventId);
    if (o) {
        o.setValue(undefined);
    }
    return getEvent(eventId, errorCollection);
}

async function fetchEvent(eventId: number): Promise<MoraleEvent> {
    return simpleGet(`/api/event/${eventId}`);
}

export async function addEvent(event: NewMoraleEvent): Promise<undefined | string[]> {
    return postWithValidation("/api/event", event);
}

export async function addRound(eventId: number, round: EditRound): Promise<undefined | string[]> {
    const result = await postWithValidation<MoraleEvent>(`/api/event/${eventId}/round`, round);
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

export async function editRound(eventId: number, roundId: number, round: EditRound): Promise<undefined | string[]> {
    const result = await patchWithValidation<MoraleEvent>(`/api/event/${eventId}/round/${roundId}`, round);
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

export async function addOption(eventId: number, roundId: number, option: NewOption): Promise<undefined | string[]> {
    const result = await postWithValidation<MoraleEvent>(`/api/event/${eventId}/round/${roundId}/option`, option);
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

export async function deleteOption(eventId: number, roundId: number, optionId: number): Promise<undefined | string[]> {
    const result = await requestWithValidation("DELETE", `/api/event/${eventId}/round/${roundId}/option/${optionId}`);
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

export async function abstain(
    eventId: number,
    roundId: number,
    action: "abstain" | "return"
): Promise<undefined | string[]> {
    const result = await requestWithValidation<MoraleEvent>(
        action === "abstain" ? "POST" : "DELETE",
        `/api/event/${eventId}/round/${roundId}/abstain`
    );
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

export async function vote(
    eventId: number,
    roundId: number,
    optionId: number,
    action: "add" | "remove"
): Promise<undefined | string[]> {
    const result = await requestWithValidation<MoraleEvent>(
        action === "add" ? "POST" : "DELETE",
        `/api/event/${eventId}/round/${roundId}/option/${optionId}/vote`
    );
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

export async function addComment(
    eventId: number,
    roundId: number,
    optionId: number,
    comment: string
): Promise<undefined | string[]> {
    const result = await postWithValidation<MoraleEvent>(
        `/api/event/${eventId}/round/${roundId}/option/${optionId}/comment`,
        { comment }
    );
    if (Array.isArray(result)) {
        return result;
    } else if (result) {
        updateCachedEvent(result);
    }
}

function updateCachedEvent(event: MoraleEvent): void {
    const observable = eventCache.getOptional(event.eventId);
    if (observable) {
        observable.setValue(event);
    }
}

/* eslint-disable no-console */
const MIN_BACKOFF = 1000;
function _subscribe(eventId: number, connected: IWritableObservable<boolean>): () => void {
    const url = `/api/event/${eventId}/stream`;
    let source: EventSource | undefined;
    let backoff = MIN_BACKOFF;
    const connect = (): void => {
        console.log("Connecting to event subscription.");
        source = new EventSource(url);

        source.onopen = e => {
            connected.setValue(true);
            console.log("Connected to event subscription.");
            if (backoff > MIN_BACKOFF) {
                backoff /= 2;
            }
        };
        source.addEventListener("update", e => {
            console.log("Event update received");
            const event = JSON.parse(e.data);
            updateCachedEvent(event);
        });
        source.onerror = async e => {
            source?.close();
            source = undefined;
            connected.setValue(false);
            backoff *= 2;
            const wait = backoff + Math.random() * backoff * 0.1;
            console.log(`Lost event subscription, waiting ${wait}ms before reconnecting.`);
            await delay(wait);
            connect();
        };
    };

    connect();

    return () => {
        source?.close();
    };
}
export const subscribe = rateLimit(_subscribe, 4, 60 * 1000);
/* eslint-enable no-console */
