import * as React from "react";
import Container from "react-bootstrap/Container";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { renderDialogs } from "../components/dialogs";
import { MessageContextProvider } from "../components/mealert";
import { Event } from "./event";
import { JoinTeam } from "./jointeam";
import { RoundComponent } from "./round";
import { Team } from "./team";
import { TeamList } from "./teamlist";
import { Users } from "./users";

export function Root(): JSX.Element {
    return (
        <BrowserRouter>
            <Container>
                <MessageContextProvider>
                    <Routes>
                        <Route path="/event/:eventId/round/:roundId" element={<RoundComponent />} />
                        <Route path="/event/:eventId" element={<Event />} />
                        <Route path="/team/join/:inviteCode" element={<JoinTeam />} />
                        <Route path="/team/:teamId" element={<Team />} />
                        <Route path="/user/list" element={<Users />} />
                        <Route path="/" element={<TeamList />} />
                    </Routes>
                </MessageContextProvider>
            </Container>
            {renderDialogs()}
        </BrowserRouter>
    );
}
