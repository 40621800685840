import React from "react";

import { User } from "../api/user";

export interface AvatarProps {
    user: User;
    size: number;
    className?: string;
    alt?: string;
}
export function Avatar({ user, size, className, alt }: AvatarProps): JSX.Element {
    return <img
        className={className}
        style={{ width: size, height: size, borderRadius: size < 24 ? "4px" : "6px" }}
        alt={alt || ""}
        src={`${user.avatarUrl}&s=${size*3}`}
    />;
}