import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { ErrorCollection } from "../api/common";

import { SlimTeam, Team, getAllTeams, getUserTeams } from "../api/team";
import { MeContext } from "../common/mecontext";
import { MeAlert } from "../components/mealert";
import { MeNavbar } from "../components/menavbar";
import { TeamDialog } from "./team";

export function TeamList(): JSX.Element {
    const [myTeamList, setMyTeamList] = useState<SlimTeam[]>();
    const [allTeamList, setAllTeamList] = useState<SlimTeam[]>();
    const [loadAllTeamList, setLoadAllTeamList] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [errorCollection] = useState(() => new ErrorCollection());
    const context = useContext(MeContext);

    useEffect(
        () =>
            void getUserTeams().then(
                teams => setMyTeamList(teams),
                error => errorCollection.push(error.message || error)
            ),
        [errorCollection]
    );
    useEffect(
        () =>
            void (
                loadAllTeamList &&
                getAllTeams().then(
                    teams => setAllTeamList(teams),
                    error => errorCollection.push(error.message || error)
                )
            ),
        [errorCollection, loadAllTeamList]
    );

    return (
        <>
            <MeNavbar
                buttons={[
                    context.currentUser.isSuperAdmin &&
                        showAll && { text: "Show my teams", onClick: () => setShowAll(false), variant: "secondary" },
                    context.currentUser.isSuperAdmin &&
                        !showAll && {
                            text: "Show all teams",
                            onClick: () => {
                                setShowAll(true);
                                setLoadAllTeamList(true);
                            },
                            variant: "secondary",
                        },
                    context.currentUser.isSuperAdmin && { text: "Add Team", onClick: () => setShowAddModal(true) },
                ]}
            />
            <Row className="justify-content-between">
                <Col md="auto">{showAll ? "All" : "My"} Teams</Col>
            </Row>
            <Row>
                <Col>
                    <MeAlert errorCollection={errorCollection} />
                    <TeamListTable teamList={showAll ? allTeamList : myTeamList} />
                </Col>
            </Row>
            {showAddModal && (
                <TeamDialog
                    context={context}
                    onDismiss={(newTeam?: Team) => {
                        if (newTeam) {
                            setAllTeamList(allTeamList => (allTeamList ? [...allTeamList, newTeam] : allTeamList));
                            setMyTeamList(myTeamList => (myTeamList ? [...myTeamList, newTeam] : myTeamList));
                        }
                        setShowAddModal(false);
                    }}
                />
            )}
        </>
    );
}

interface TeamListTableProps {
    teamList: SlimTeam[] | undefined;
}

function TeamListTable({ teamList }: TeamListTableProps): JSX.Element {
    if (!teamList) {
        return <Spinner animation="border" />;
    } else if (teamList.length === 0) {
        return <span>(no teams!)</span>;
    } else {
        return (
            <Table className="mt-3">
                <tbody>
                    {teamList ? (
                        teamList.map((t, i) => (
                            <tr key={i}>
                                <td>
                                    <Link to={`/team/${t.teamId}`}>{t.name}</Link>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <Spinner animation="border" />
                    )}
                </tbody>
            </Table>
        );
    }
}
