import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";

export interface TimeRemaining {
    timeRemainingNumber: number;
    timeRemainingString: string;
}

export interface TimeObserverProps {
    children: (props: TimeRemaining) => ReactElement | null;
    endTime: number;
}

export function CountdownObserver({ children, endTime }: TimeObserverProps): ReactElement | null {
    const [timeRemaining, setTimeRemaining] = useState(() => getTimeRemaining(endTime));

    useEffect(() => {
        let timeout = -1;
        function callback(): void {
            setTimeRemaining(() => getTimeRemaining(endTime));
            timeout = window.setTimeout(callback, getInterval(endTime));
        }
        callback();
        return () => window.clearTimeout(timeout);
    }, [endTime]);

    return children(timeRemaining);
}

function getInterval(endTime: number): number {
    const timeRemaining = (endTime - Date.now()) / 1000;
    if (timeRemaining < 0) {
        return Number.MAX_SAFE_INTEGER;
    } else if (timeRemaining < 60) {
        return 1000;
    } else if (timeRemaining < 60 * 60) {
        return 1000 * 10;
    } else {
        return 1000 * 60;
    }
}

function getTimeRemaining(endTime: number): TimeRemaining {
    const timeRemainingNumber = moment(endTime).diff(Date.now());
    return {
        timeRemainingNumber,
        timeRemainingString: moment.duration(timeRemainingNumber).humanize(true),
    };
}
