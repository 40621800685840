import { ObservableArray } from "../common/observable";
import { errorToString } from "../common/util";

export async function simpleGet(url: string): Promise<any> {
    try {
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        }
        throw Error(response.statusText);
    } catch (ex: any) {
        // eslint-disable-next-line no-console
        console.error(ex);
        throw Error(ex.message || ex);
    }
}

export async function patchWithValidation<T = undefined>(
    url: string,
    value: unknown
): Promise<T | string[] | undefined> {
    return requestWithValidation("PATCH", url, value);
}

export async function postWithValidation<T = undefined>(
    url: string,
    value: unknown
): Promise<T | string[] | undefined> {
    return requestWithValidation("POST", url, value);
}

export async function deleteWithValidation<T = undefined>(
    url: string,
    value: unknown
): Promise<T | string[] | undefined> {
    return requestWithValidation("DELETE", url, value);
}

export async function requestWithValidation<T = undefined>(
    method: "PATCH" | "POST" | "DELETE",
    url: string,
    value?: unknown
): Promise<T | string[] | undefined> {
    try {
        const response = await fetch(url, {
            method,
            headers: {
                "Content-Type": "application/json",
            },
            body: value === undefined ? undefined : JSON.stringify(value),
        });
        if (response.ok) {
            if (response.status !== 204) {
                return response.json();
            } else {
                return undefined;
            }
        } else if (response.headers.get("Content-Type")?.startsWith("application/json")) {
            const json = await response.json();
            if (Array.isArray(json) && json.length > 0 && typeof json[0] === "string") {
                return json as string[];
            }
        }
        return [`${response.status}: ${response.statusText}`];
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
        return [errorToString(ex)];
    }
}

export class ErrorCollection extends ObservableArray<string> {
    public constructor() {
        super([]);
    }
}
