import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ObservableArray } from "../common/observable";
import { Observer } from "./observer";

export const ConfirmationDialog = ({
    children,
    primaryButtonText,
    onDismiss,
}: React.PropsWithChildren<{
    primaryButtonText: string;
    onDismiss: (confirmed: boolean) => void;
}>): JSX.Element => {
    return (
        <Modal show={true} onHide={() => onDismiss(false)}>
            <Modal.Header>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onDismiss(true)}>
                    {primaryButtonText}
                </Button>
                <Button variant="secondary" onClick={() => onDismiss(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

interface DialogRecord {
    id: number;
    renderDialog: () => JSX.Element;
}
let nextDialogId = 0;
const dialogs = new ObservableArray<DialogRecord>([]);

export function showDialog(renderDialog: (onDismiss: () => void) => JSX.Element): void {
    const id = nextDialogId++;
    const remover = (): void => {
        dialogs.setValue(dialogs.getValue().filter(d => d.id !== id));
    };
    const renderer = (): JSX.Element => renderDialog(remover);
    dialogs.push({ id, renderDialog: renderer });
}

export function showConfirmationDialog(text: string, primaryButtonText: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
        showDialog(onDismiss => (
            <ConfirmationDialog
                primaryButtonText={primaryButtonText}
                onDismiss={confirmed => {
                    onDismiss();
                    resolve(confirmed);
                }}
            >
                {text}
            </ConfirmationDialog>
        ));
    });
}

function DialogManager({ dialogs }: { dialogs: ObservableArray<DialogRecord> }): JSX.Element {
    return (
        <Observer observable={dialogs}>
            {observedDialogs => (
                <div className="dialogs">
                    {observedDialogs.map(d => (
                        <div key={d.id}>{d.renderDialog()}</div>
                    ))}
                </div>
            )}
        </Observer>
    );
}

export const renderDialogs = (): JSX.Element => <DialogManager dialogs={dialogs} />;
