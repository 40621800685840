import React, { useLayoutEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

import { ErrorCollection } from "../api/common";
import { addGitHubUser, approveUser, disableUser, getAllUsers } from "../api/user";
import { Avatar } from "../components/avatar";
import { ConfirmationDialog, showDialog } from "../components/dialogs";
import { MeAlert } from "../components/mealert";
import { MeNavbar } from "../components/menavbar";
import { Observer } from "../components/observer";

export function Users(): JSX.Element {
    const [invitedUsersObservable] = useState(() => getAllUsers());
    const [errorCollection] = useState(() => new ErrorCollection());
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    return (
        <>
            <MeNavbar buttons={[{ text: "Add User", onClick: () => setShowAddUserModal(true) }]} />
            <Row>
                <Col>
                    <MeAlert errorCollection={errorCollection} />
                    <Observer observable={invitedUsersObservable}>
                        {users => (
                            <>
                                {users.length === 0 && invitedUsersObservable.loading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <th>User</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            {users.map((observable, i) => (
                                                <Observer key={i} observable={observable}>
                                                    {user => (
                                                        <tr>
                                                            <td>
                                                                <Avatar user={user} size={30} /> {user.username}
                                                                {user.isSuperAdmin && " (superadmin)"}
                                                            </td>
                                                            <td>{user.status}</td>
                                                            <td>
                                                                {user.status !== "approved" && (
                                                                    <Button
                                                                        onClick={() =>
                                                                            approveUser(observable, errorCollection)
                                                                        }
                                                                    >
                                                                        {user.status === "pending"
                                                                            ? "Approve"
                                                                            : "Re-enable"}
                                                                    </Button>
                                                                )}
                                                                {user.status === "approved" && (
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                            showDialog(onDismiss => (
                                                                                <ConfirmationDialog
                                                                                    primaryButtonText="Disable user"
                                                                                    onDismiss={confirmed => {
                                                                                        if (confirmed) {
                                                                                            disableUser(
                                                                                                observable,
                                                                                                errorCollection
                                                                                            );
                                                                                        }
                                                                                        onDismiss();
                                                                                    }}
                                                                                >
                                                                                    Are you sure you want to disable
                                                                                    {user.username}?
                                                                                </ConfirmationDialog>
                                                                            ));
                                                                        }}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Observer>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </>
                        )}
                    </Observer>
                    {showAddUserModal && <AddUserModal onDismiss={() => setShowAddUserModal(false)} />}
                </Col>
            </Row>
        </>
    );
}

function AddUserModal({ onDismiss }: { onDismiss: () => void }): JSX.Element {
    const [errorCollection] = useState(() => new ErrorCollection());
    const [busy, setBusy] = useState(false);
    const nameInput = useRef<typeof FormControl & HTMLInputElement>(null);
    useLayoutEffect(() => void nameInput.current?.focus(), []);

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss()}>
            <Modal.Header>
                <Modal.Title>Add Team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MeAlert errorCollection={errorCollection} />
                <Form
                    onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            onSave();
                        }
                    }}
                >
                    <Form.Group controlId="username">
                        <Form.Label>GitHub Username</Form.Label>
                        <Form.Control type="text" maxLength={50} ref={nameInput} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={busy} onClick={onSave}>
                    Add User
                </Button>
                <Button variant="secondary" disabled={busy} onClick={(): void => onDismiss()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onSave(): Promise<void> {
        setBusy(true);
        errorCollection.clear();
        const errors = await addGitHubUser(nameInput.current!.value);
        if (Array.isArray(errors)) {
            errorCollection.setValue(errors);
        } else {
            onDismiss();
        }
        setBusy(false);
    }
}
