import { ErrorCollection, postWithValidation, simpleGet } from "./common";
import { Cache } from "../common/cache";
import { LoadingObservableArray, Observable } from "../common/observable";

export interface User {
    userId: number;
    username: string;
    avatarUrl: string;
    status: "approved" | "pending" | "disabled";
    isSuperAdmin: boolean;
}

export interface Context {
    currentUser: User;
    clientId: string;
    sha?: string;
    version?: string;
}

let contextPromise: Promise<Context | null> | undefined;

export function getContext(): Promise<Context | null> {
    if (!contextPromise) {
        contextPromise = fetch("/api/user/context").then(res => {
            contextPromise = undefined;
            if (!res.ok) {
                if (res.status === 401) {
                    return null;
                }
                throw Error(`Could not fetch context: ${res.status}: ${res.statusText}`);
            }
            return res.json() as Promise<Context>;
        });
    }
    return contextPromise;
}

export async function logout(): Promise<boolean> {
    const res = await fetch("/logout", { method: "POST" });
    return res.ok;
}

const userCache = new Cache<User | undefined, string>();
type getUserQueueEntry = {
    username: string;
    promise: Promise<User>;
    setError: (e: string) => void;
    resolve: (u: User) => void;
    reject: (e: any) => void;
};
let getUserQueue: getUserQueueEntry[] = [];
let getUsersPromise: Promise<void> | undefined;
// TODO: handle errors better
export function getUser(username: string, errorCollection: ErrorCollection): Observable<User | undefined> {
    return userCache.get(username, () => {
        const existing = getUserQueue.find(x => x.username === username);
        if (!existing) {
            let o: any;
            const p = new Promise((resolve, reject) => {
                o = { username, resolve, reject };
            });
            o.promise = p;
            getUserQueue.push(o);
            if (!getUsersPromise) {
                getUsersPromise = Promise.resolve().then(getUsers);
            }
            return o.promise;
        }
        return existing.promise;
    });
}

async function getUsers(): Promise<void> {
    const currentQueue = getUserQueue;
    getUserQueue = [];
    getUsersPromise = undefined;
    const usernames = currentQueue.map(x => x.username);
    const result: User[] = await simpleGet(`/api/user?ids=${usernames.join(",")}`);
    if (result.length !== usernames.length) {
        // eslint-disable-next-line no-console
        console.error(`Expected result with length ${usernames.length} from getUsers, got ${result.length} instead!`);
        return;
    }
    for (let i = 0; i < currentQueue.length; i++) {
        if (result[i].username !== currentQueue[i].username) {
            // eslint-disable-next-line no-console
            console.error(`Expected username ${currentQueue[i].username}, got ${result[i].username} instead!`);
        } else {
            currentQueue[i].resolve(result[i]);
        }
    }
}

// sorted array of InvitedUsers
const allUsers = new LoadingObservableArray<Observable<User>>([]);

// refresh and return the ObservableArray of InvitedUsers.
export function getAllUsers(): LoadingObservableArray<Observable<User>> {
    allUsers.loading = true;
    fetchAllUsers();
    return allUsers;
}

async function fetchAllUsers(): Promise<void> {
    // get users from server
    const users = (await simpleGet("/api/user/all")) as User[];

    users.sort((a, b) => a.username.localeCompare(b.username));
    const observables = users.map(u => userCache.set(u.username, u) as Observable<User>);

    allUsers.setValue(observables);
    allUsers.loading = false;
}

export async function approveUser(user: Observable<User>, errorCollection: ErrorCollection): Promise<void> {
    const result = await postWithValidation<User>("/api/user/approve", { username: user.getValue().username });
    if (Array.isArray(result)) {
        errorCollection.setValue(result);
    } else if (result) {
        user.setValue(result);
    }
}

export async function disableUser(user: Observable<User>, errorCollection: ErrorCollection): Promise<void> {
    const result = await postWithValidation<User>("/api/user/disable", { username: user.getValue().username });
    if (Array.isArray(result)) {
        errorCollection.setValue(result);
    } else if (result) {
        user.setValue(result);
    }
}

export async function addGitHubUser(username: string): Promise<string[] | User | undefined> {
    const result = await postWithValidation<User>("/api/user/add", { username });
    if (result && !Array.isArray(result)) {
        fetchAllUsers();
    }
    return result;
}
