import React, { PropsWithChildren, ReactElement, useContext } from "react";
import Alert from "react-bootstrap/Alert";
import { useLocation } from "react-router-dom";

import { ErrorCollection } from "../api/common";
import { Observer } from "./observer";

type LocationMessage = { text: string; location: string };

export const MessageContext = React.createContext<{
    message: LocationMessage | undefined;
    setMessage: (message: LocationMessage | undefined) => void;
}>({
    message: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMessage: () => {},
});

// eslint-disable-next-line @typescript-eslint/ban-types
export function MessageContextProvider(props: PropsWithChildren<{}>): ReactElement {
    const [message, setMessage] = React.useState<LocationMessage | undefined>(undefined);
    return <MessageContext.Provider value={{ message, setMessage }}>{props.children}</MessageContext.Provider>;
}

export function MeAlert({ errorCollection }: { errorCollection: ErrorCollection }): ReactElement {
    const messageContext = useContext(MessageContext);
    const location = useLocation();
    return (
        <>
            <Observer observable={errorCollection}>
                {errors =>
                    errors.length > 0 ? (
                        <Alert variant="danger" dismissible onClose={() => errorCollection.clear()}>
                            {errors.map((e, i) => (
                                <div key={i}>{e}</div>
                            ))}
                        </Alert>
                    ) : null
                }
            </Observer>
            {messageContext.message?.location === location.pathname && (
                <Alert variant="success" dismissible onClose={() => messageContext.setMessage(undefined)}>
                    {messageContext.message.text}
                </Alert>
            )}
        </>
    );
}
