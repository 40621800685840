import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";

import { ErrorCollection } from "../api/common";
import { MoraleEvent } from "../api/event";
import {
    NewTeam,
    Team,
    TeamMember,
    addAdmin,
    addTeam,
    clearInviteCode,
    getTeam,
    getTeamEvents,
    getTeamMembers,
    joinTeam,
    leaveTeam,
    removeAdmin,
    removeMember,
    updateInviteCode,
} from "../api/team";
import { Context } from "../api/user";
import { errorToString } from "../common/util";
import { MeAlert } from "../components/mealert";
import { MeNavbar } from "../components/menavbar";
import { Observer } from "../components/observer";
import { EventDialog } from "./event";
import { ConfirmationDialog, showConfirmationDialog } from "../components/dialogs";

export function Team(): JSX.Element {
    const { teamId } = useParams<{ teamId: string }>();
    const [eventList, setEventList] = useState<MoraleEvent[]>();
    const [showMemberList, setShowMemberList] = useState(false);
    const [showAddEventModal, setShowAddEventModal] = useState(false);
    const [errorCollection] = useState(() => new ErrorCollection());
    const [leaving, setLeaving] = useState(false);
    const [teamObservable] = useState(() => getTeam(Number(teamId), errorCollection));
    const loadEvents = useCallback(() => {
        getTeamEvents(Number(teamId)).then(
            list => setEventList(list),
            e => errorCollection.push(errorToString(e))
        );
    }, [teamId, errorCollection]);

    useEffect(() => loadEvents(), [teamId, loadEvents]);

    return (
        <>
            <Observer observable={teamObservable}>
                {team => (
                    <>
                        <MeNavbar
                            breadcrumbs={[team && { text: team.name, active: true }]}
                            buttons={[
                                team?.isAdmin && { text: "Add Event", onClick: () => setShowAddEventModal(true) },
                                team && { text: "Members", onClick: () => setShowMemberList(true) },
                                team?.isMember && {
                                    text: "Leave Team",
                                    variant: "danger",
                                    onClick: () => setLeaving(true),
                                },
                                team && !team.isMember && { text: "Join Team", onClick: () => joinTeam(team.teamId) },
                                team &&
                                    !team.isAdmin &&
                                    team.inviteCode && {
                                        text: "Copy Invite Link",
                                        onClick: () =>
                                            navigator.clipboard.writeText(
                                                window.location.origin + "/team/join/" + team.inviteCode
                                            ),
                                    },
                                team &&
                                    team.isAdmin && {
                                        type: "dropdown",
                                        text: "Invite Link",
                                        items: [
                                            {
                                                text: "Copy Invite Link",
                                                disabled: !team.inviteCode,
                                                onClick: () =>
                                                    navigator.clipboard.writeText(
                                                        window.location.origin + "/team/join/" + team.inviteCode
                                                    ),
                                            },
                                            {
                                                text: "New Invite Link",
                                                onClick: async () => {
                                                    if (
                                                        team.inviteCode &&
                                                        !(await showConfirmationDialog(
                                                            "Are you sure you want to generate a new invite link? The old one will no longer work.",
                                                            "Continue"
                                                        ))
                                                    ) {
                                                        return;
                                                    }
                                                    const result = await updateInviteCode(team.teamId);
                                                    if (result) {
                                                        errorCollection.push(...result);
                                                    }
                                                },
                                            },
                                            {
                                                text: "Clear Invite Link",
                                                disabled: !team.inviteCode,
                                                onClick: async () => {
                                                    if (
                                                        team.inviteCode &&
                                                        !(await showConfirmationDialog(
                                                            "Are you sure you want to clear the invite link? The old one will no longer work.",
                                                            "Continue"
                                                        ))
                                                    ) {
                                                        return;
                                                    }
                                                    const result = await clearInviteCode(team.teamId);
                                                    if (result) {
                                                        errorCollection.push(...result);
                                                    }
                                                },
                                            },
                                        ],
                                    },
                            ]}
                        />
                        <Row>
                            <Col>
                                <MeAlert errorCollection={errorCollection} />
                                {team === undefined ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <>
                                        <Table className="mt-3">
                                            <tbody>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                </tr>
                                                {eventList ? (
                                                    eventList.map((e, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <Link to={`/event/${e.eventId}`}>{e.name}</Link>
                                                            </td>
                                                            <td>{new Date(e.when).toLocaleDateString()}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td>
                                                            <Spinner animation="border" />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                        {showAddEventModal && <EventDialog team={team} onDismiss={onAddEventDismiss} />}
                                        {showMemberList && (
                                            <MemberListModal team={team} onDismiss={() => setShowMemberList(false)} />
                                        )}
                                        {leaving && <ConfirmLeaveDialog team={team} onDismiss={onLeaveDismiss} />}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Observer>
        </>
    );

    function onAddEventDismiss(added: boolean): void {
        setShowAddEventModal(false);
        if (added) {
            setEventList(undefined);
            loadEvents();
        }
    }

    async function onLeaveDismiss(confirmed: boolean): Promise<void> {
        setLeaving(false);
        if (confirmed) {
            await leaveTeam(teamObservable.getValue()!.teamId);
        }
    }
}

function MemberListModal({ team, onDismiss }: { team: Team; onDismiss: () => void }): JSX.Element {
    const [errors, setErrors] = useState<string[] | null>(null);
    const [busy, setBusy] = useState(false);
    const [members, setMembers] = useState<TeamMember[]>();
    const [serial, setSerial] = useState(0);

    useEffect(
        () =>
            void getTeamMembers(team.teamId).then(
                m => setMembers(m),
                e => setErrors([errorToString(e)])
            ),
        [serial, team.teamId]
    );

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss()}>
            <Modal.Header>
                <Modal.Title>{team.name} Members</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors && (
                    <Alert variant="danger">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Alert>
                )}
                {members ? (
                    <Table className="mt-3">
                        <tbody>
                            <tr>
                                <th>User</th>
                                <th>Administrator</th>
                                {team.isAdmin && <th>Remove</th>}
                            </tr>
                            {members.map((member, i) => (
                                <tr key={i}>
                                    <td>
                                        <img
                                            src={`${member.user.avatarUrl}&s=90`}
                                            style={{ width: "30px", height: "30px" }}
                                        />{" "}
                                        {member.user.username}
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            title="is administrator"
                                            checked={member.isAdmin}
                                            readOnly={busy || !team.isAdmin}
                                            onClick={() => false}
                                            onInput={e =>
                                                team.isAdmin && onAdminChange(member.user.username, !member.isAdmin)
                                            }
                                        />
                                    </td>
                                    {team.isAdmin && (
                                        <td>
                                            <Button
                                                variant="danger"
                                                disabled={busy}
                                                onClick={() => onRemove(member.user.username)}
                                            >
                                                Remove
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <Spinner animation="border" />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onDismiss}>Close</Button>
            </Modal.Footer>
        </Modal>
    );

    async function onAdminChange(username: string, isAdmin: boolean): Promise<void> {
        try {
            setBusy(true);
            setErrors(null);
            let errors: string[] | undefined;
            if (isAdmin) {
                errors = await addAdmin(team.teamId, username);
            } else {
                errors = await removeAdmin(team.teamId, username);
            }
            if (errors) {
                setErrors(errors);
            } else {
                setMembers(undefined);
                setSerial(s => s + 1);
            }
        } catch (ex) {
            setErrors([errorToString(ex)]);
        } finally {
            setBusy(false);
        }
    }

    async function onRemove(username: string): Promise<void> {
        try {
            if (
                !(await showConfirmationDialog(
                    `Are you sure you want to remove ${username} from ${team.name}?`,
                    "Remove"
                ))
            ) {
                return;
            }
            setBusy(true);
            setErrors(null);
            const errors = await removeMember(team.teamId, username);
            if (errors) {
                setErrors(errors);
            } else {
                setMembers(undefined);
                setSerial(s => s + 1);
            }
        } catch (ex) {
            setErrors([errorToString(ex)]);
        } finally {
            setBusy(false);
        }
    }
}

function ConfirmLeaveDialog({ team, onDismiss }: { team: Team; onDismiss: (confirmed: boolean) => void }): JSX.Element {
    return (
        <ConfirmationDialog primaryButtonText="Leave team" onDismiss={onDismiss}>
            Are you sure you want to leave team {team.name}?
        </ConfirmationDialog>
    );
}

interface TeamDialogProps {
    context: Context;
    onDismiss: (newTeam?: Team) => void;
}

export function TeamDialog({ context, onDismiss }: TeamDialogProps): JSX.Element {
    const [busy, setBusy] = useState(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const nameInput = useRef<typeof FormControl & HTMLInputElement>(null);
    useLayoutEffect(() => void nameInput.current?.focus(), []);

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss()}>
            <Modal.Header>
                <Modal.Title>Add Team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors && (
                    <Alert variant="danger">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Alert>
                )}
                <Form
                    onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            onSave();
                        }
                    }}
                >
                    <Form.Group controlId="teamName">
                        <Form.Label>Team Name</Form.Label>
                        <Form.Control type="text" maxLength={50} ref={nameInput} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={busy} onClick={onSave}>
                    Add Team
                </Button>
                <Button variant="secondary" disabled={busy} onClick={(): void => onDismiss()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onSave(): Promise<void> {
        setBusy(true);
        setErrors(null);
        const team: NewTeam = {
            name: nameInput.current!.value,
            admins: [context.currentUser.username],
        };
        const result = await addTeam(team);
        if (Array.isArray(result)) {
            setErrors(result);
        } else {
            onDismiss(result);
        }
        setBusy(false);
    }
}
