import moment, { Moment } from "moment";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import FormControl from "react-bootstrap/FormControl";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Tooltip from "react-bootstrap/Tooltip";
import DateTime from "react-datetime";
import { Link, useParams } from "react-router-dom";

import { ErrorCollection } from "../api/common";
import {
    Comment,
    EditRound,
    MoraleEvent,
    NewOption,
    Option,
    Round,
    abstain,
    addComment,
    addOption,
    addRound,
    deleteOption,
    editRound,
    subscribe,
    vote,
} from "../api/event";
import { Team, TeamMember, getTeamEvents, getTeamMembers } from "../api/team";
import { Context, User, getUser } from "../api/user";
import { MeContext } from "../common/mecontext";
import { DelayedObservable, Observable } from "../common/observable";
import { errorToString, mulberry32, pluralize, renderMarkdown, useRenderLog } from "../common/util";
import { Avatar } from "../components/avatar";
import { CountdownObserver } from "../components/countdownobserver";
import { ConfirmationDialog, showDialog } from "../components/dialogs";
import { MeAlert } from "../components/mealert";
import { MeNavbar } from "../components/menavbar";
import { Observer } from "../components/observer";
import { EventObserver } from "./event";

import "./round.css";

export function RoundComponent(): JSX.Element {
    useRenderLog("Round");
    const [errorCollection] = useState(() => new ErrorCollection());
    const { eventId: eventIdStr } = useParams<{ eventId: string }>();
    const eventId = Number(eventIdStr);
    const { roundId } = useParams<{ roundId: string }>();
    return (
        <EventObserver eventId={eventId} errorCollection={errorCollection}>
            {({ event, team, errorCollection }) => (
                <RoundImpl event={event} team={team} errorCollection={errorCollection} roundId={Number(roundId)} />
            )}
        </EventObserver>
    );
}

const defaultCommentsToShow = 2;

interface RoundImplProps {
    event: MoraleEvent | undefined;
    team: Team | undefined;
    roundId: number;
    errorCollection: ErrorCollection;
}
function RoundImpl({ event, team, roundId, errorCollection }: RoundImplProps): JSX.Element {
    useRenderLog("RoundImpl");
    const context = useContext(MeContext);

    const round = event && event.rounds[roundId];
    const canAddOption = team?.isMember && (team?.isAdmin || round?.usersCanAddOptions);
    const augmentedRound = round && team && augmentRound(round, roundId, team, context);

    return (
        <>
            <MeNavbar
                breadcrumbs={[
                    team && { text: team.name, href: `/team/${team.teamId}` },
                    event && { text: event.name, href: `/event/${event.eventId}` },
                    round && { text: round.name, active: true },
                ]}
                buttons={[
                    team?.isAdmin && {
                        text: "Edit Round",
                        onClick: () =>
                            showDialog(onDismiss => (
                                <RoundDialog
                                    event={event!}
                                    existing={round}
                                    existingRoundId={roundId}
                                    onDismiss={onDismiss}
                                />
                            )),
                    },
                    event &&
                        team?.isAdmin && {
                            text: "Copy Options from Previous Round",
                            onClick: () =>
                                showDialog(onDismiss => (
                                    <CopyOptionsDialog
                                        errorCollection={errorCollection}
                                        targetEvent={event}
                                        targetRoundId={roundId}
                                        team={team}
                                        onDismiss={onDismiss}
                                    />
                                )),
                        },
                    canAddOption && {
                        text: "Add Option",
                        onClick: () =>
                            showDialog(onDismiss => (
                                <AddOptionDialog event={event!} roundId={roundId} onDismiss={onDismiss} />
                            )),
                    },
                    augmentedRound?.canAbstain && {
                        text: "Abstain",
                        onClick: () => abstain(event!.eventId, roundId, "abstain"),
                    },
                    augmentedRound?.canReturn && {
                        text: "Return",
                        onClick: () => abstain(event!.eventId, roundId, "return"),
                    },
                ]}
            />
            <Row>
                <Col>
                    {augmentedRound && (
                        <RoundContent
                            errorCollection={errorCollection}
                            event={event}
                            round={augmentedRound}
                            roundId={roundId}
                            team={team}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function augmentRound(round: Round, roundId: number, team: Team, context: Context) {
    const username = context.currentUser.username;
    const votingOpen = Date.now() < round.closeTime;
    const userVotes = round.options.filter(o => o.votes.includes(username)).length;
    const abstained = round.abstentions.includes(username);
    const canVote = votingOpen && team.isMember && round.votesPerUser > userVotes && !abstained;
    // highest number of votes any option has gotten
    const maxVotes = round?.options.reduce((max, o) => (o.votes.length > max ? o.votes.length : max), 0) ?? 0;
    const canSeeResults = !votingOpen || !round.hideResultsUntilVoted || userVotes >= round.votesPerUser || abstained;
    const canAbstain = canVote && userVotes === 0 && !abstained;
    const canReturn = abstained && votingOpen;

    return {
        ...round,
        abstained,
        canAbstain,
        canReturn,
        canSeeResults,
        canVote,
        maxVotes,
        roundId,
        votingOpen,
    };
}
type AugmentedRound = ReturnType<typeof augmentRound>;

function RoundContent({
    errorCollection,
    event,
    round,
    team,
}: {
    errorCollection: ErrorCollection;
    event: MoraleEvent;
    round: AugmentedRound;
    roundId: number;
    team: Team;
}): JSX.Element {
    const [firstRenderTime] = useState(() => Date.now());
    const [connected] = useState(() => new DelayedObservable(true, v => (v ? 0 : 5000)));
    const [teamMembers, setTeamMembers] = useState<TeamMember[] | undefined>();

    React.useMemo(() => getTeamMembers(team.teamId).then(members => setTeamMembers(members)), [team.teamId]);
    const voted = React.useMemo(() => {
        if (!round || !teamMembers) {
            return undefined;
        }
        const x = { voted: [] as User[], notVoted: [] as User[], abstaining: [] as User[] };
        for (const m of teamMembers) {
            if (round.abstentions.includes(m.user.username)) {
                x.abstaining.push(m.user);
            } else if (round.options.some(o => o.votes.includes(m.user.username))) {
                x.voted.push(m.user);
            } else {
                x.notVoted.push(m.user);
            }
        }
        return x;
    }, [teamMembers, round]);

    useEffect(() => {
        if (round.votingOpen) {
            try {
                return subscribe(event.eventId, connected);
            } catch (err) {
                errorCollection.push(`Error subscribing to live updates: ${errorToString(err)}`);
            }
        }
    }, [connected, errorCollection, event.eventId, round.votingOpen]);

    const options = augmentOptions(round.options, firstRenderTime);
    if (round.canVote) {
        // shuffle options while user is voting
        options.sort((a, b) => a.order - b.order);
    } else {
        options.sort((a, b) => b.votes.length - a.votes.length);
    }

    return (
        <>
            <p>{round.description}</p>
            <CountdownObserver endTime={round.closeTime}>
                {timeRemaining =>
                    timeRemaining.timeRemainingNumber > 0 ? (
                        <p>
                            {"Voting closes "}
                            <OverlayTrigger
                                overlay={(props: any) => (
                                    <Tooltip {...props}>{moment(round.closeTime).format("LLLL")}</Tooltip>
                                )}
                            >
                                <span>{timeRemaining.timeRemainingString}.</span>
                            </OverlayTrigger>
                        </p>
                    ) : (
                        <p>Voting is closed.</p>
                    )
                }
            </CountdownObserver>
            <p>
                Users can vote for
                {round.votesPerUser === 1 ? " one option" : ` up to ${round.votesPerUser} options`} and
                {round.usersCanAddOptions ? " can" : " cannot"} add options.
            </p>
            {voted && (
                <p>
                    <UserListOnHover
                        id="voted-users"
                        text={pluralize(
                            voted.voted.length,
                            "1 team member has voted",
                            `${voted.voted.length} team members have voted`
                        )}
                        users={voted.voted}
                    />
                    <UserListOnHover
                        id="not-voted-users"
                        text={pluralize(
                            voted.notVoted.length,
                            "1 team member has not voted",
                            `${voted.notVoted.length} team members have not voted`
                        )}
                        users={voted.notVoted}
                    />
                    <UserListOnHover
                        id="abstaining-users"
                        text={pluralize(
                            voted.abstaining.length,
                            "1 team member has abstained",
                            `${voted.abstaining.length} team members have abstained`
                        )}
                        users={voted.abstaining}
                    />
                </p>
            )}
            {round.votingOpen && !team.isMember && (
                <Alert variant="info">
                    You must join <Link to={`/team/${team.teamId}`}>this team</Link> before you can vote.
                </Alert>
            )}
            <MeAlert errorCollection={errorCollection} />
            <Observer observable={connected}>
                {c => (c ? null : <Alert variant="warning">Lost live update connection.</Alert>)}
            </Observer>
            <Container>
                {options.map(option => (
                    <Option
                        key={option.optionId}
                        errorCollection={errorCollection}
                        event={event}
                        option={option}
                        round={round}
                        team={team}
                    />
                ))}
            </Container>
        </>
    );
}

function Option({
    errorCollection,
    event,
    option,
    round,
    team,
}: {
    errorCollection: ErrorCollection;
    event: MoraleEvent;
    option: AugmentedOption;
    round: AugmentedRound;
    team: Team;
}): JSX.Element {
    const context = useContext(MeContext);
    return (
        <Row className="my-3 p-3 align-items-baseline border border-secondary rounded">
            <Col xs={12} md={3} className="d-flex align-items-baseline">
                <div>{option.name}</div>
            </Col>
            <Col xs={12} md={3} className="d-flex flex-column">
                <div className="d-flex flex-wrap justify-content-between">
                    {round.canSeeResults && (
                        <VoteDisplay errorCollection={errorCollection} i={option.optionId} option={option} />
                    )}
                    {round.votingOpen &&
                        (option.votes.includes(context.currentUser.username) ? (
                            <Button
                                variant="outline-primary"
                                data-action="remove"
                                data-option={option.optionId}
                                size="sm"
                                onClick={onVoteClick}
                            >
                                Unvote
                            </Button>
                        ) : (
                            round.canVote && (
                                <Button
                                    variant="outline-primary"
                                    data-action="add"
                                    data-option={option.optionId}
                                    size="sm"
                                    onClick={onVoteClick}
                                >
                                    Vote!
                                </Button>
                            )
                        ))}
                    {team?.isAdmin && (
                        <Button
                            variant="outline-danger"
                            data-option={option.optionId}
                            size="sm"
                            onClick={onDeleteClick}
                        >
                            Delete
                        </Button>
                    )}
                </div>
                {round.canSeeResults && round.maxVotes > 0 && (
                    <div className="votebar-container">
                        <div
                            className="votebar"
                            style={{
                                width: (option.votes.length / round.maxVotes) * 100 + "%",
                            }}
                        />
                    </div>
                )}
            </Col>
            <Col xs={12} md={6}>
                <Comments
                    comments={option.comments}
                    errorCollection={errorCollection}
                    onAddCommentClick={() =>
                        showDialog(onDismiss => (
                            <AddCommentDialog
                                event={event}
                                roundId={round.roundId}
                                optionId={option.optionId}
                                onDismiss={onDismiss}
                            />
                        ))
                    }
                />
            </Col>
        </Row>
    );

    function onVoteClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
        const action = e.currentTarget.dataset["action"];
        const optionId = Number(e.currentTarget.dataset["option"]);

        if (event && !isNaN(optionId) && (action === "add" || action === "remove")) {
            vote(event.eventId, round.roundId, optionId, action);
        }
    }

    function onDeleteClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
        const optionId = Number(e.currentTarget.dataset["option"]);
        showDialog(onDismiss => (
            <ConfirmDeleteDialog
                option={round.options[optionId]}
                onDismiss={async confirmed => {
                    onDismiss();
                    if (confirmed && event) {
                        const result = await deleteOption(event.eventId, round.roundId, optionId);
                        if (result) {
                            errorCollection.setValue(result);
                        }
                    }
                }}
            />
        ));
    }
}

function VoteDisplay({
    errorCollection,
    i,
    option,
}: {
    errorCollection: ErrorCollection;
    i: number;
    option: Option;
}): JSX.Element {
    const content = (
        <Button size="sm" variant="outline-dark">
            {option.votes.length} {option.votes.length === 1 ? "vote" : "votes"}
        </Button>
    );
    return option.votes.length === 0 ? (
        content
    ) : (
        <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="auto"
            overlay={
                <Popover id={`votes-${i}`}>
                    <Popover.Body>
                        {option.votes.map(voterId => (
                            <Observer key={voterId} observable={getUser(voterId, errorCollection)}>
                                {u =>
                                    u ? (
                                        <div>
                                            <Avatar className="align-baseline" user={u} size={20} /> {u.username}
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Observer>
                        ))}
                    </Popover.Body>
                </Popover>
            }
        >
            {content}
        </OverlayTrigger>
    );
}

function UserListOnHover({ id, text, users }: { id: string; text: string; users: User[] }): JSX.Element {
    const content = (
        <Button className="me-2" size="sm" variant="outline-dark">
            {text}
        </Button>
    );
    return users.length === 0 ? (
        content
    ) : (
        <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="auto"
            overlay={
                <Popover id={id}>
                    <Popover.Body>
                        {users.map(u => (
                            <div key={u.userId}>
                                <Avatar className="align-baseline" user={u} size={20} /> {u.username}
                            </div>
                        ))}
                    </Popover.Body>
                </Popover>
            }
        >
            {content}
        </OverlayTrigger>
    );
}

interface ICommentsProps {
    comments: Comment[];
    errorCollection: ErrorCollection;
    onAddCommentClick: () => void;
}

function Comments({ comments, errorCollection, onAddCommentClick }: ICommentsProps): JSX.Element {
    const [expanded, setExpanded] = useState(false);
    const expandable = comments.length > defaultCommentsToShow;
    return (
        <>
            {comments.map(
                (comment, j) =>
                    (expanded || j < defaultCommentsToShow) && (
                        <div key={j} className="comment border mb-2 p-2">
                            <Observer observable={getUser(comment.username, errorCollection)}>
                                {user => (
                                    <div className="user h5">
                                        {user ? (
                                            <>
                                                <Avatar className="align-baseline" user={user} size={20} />{" "}
                                                {user.username}
                                            </>
                                        ) : (
                                            comment.username
                                        )}
                                    </div>
                                )}
                            </Observer>
                            <div className="when text-secondary">{new Date(comment.when).toLocaleString()}</div>
                            <div className="text" dangerouslySetInnerHTML={renderMarkdown(comment.comment)} />
                        </div>
                    )
            )}
            {expandable &&
                (expanded ? (
                    <Button className="me-1" variant="outline-secondary" size="sm" onClick={() => setExpanded(false)}>
                        Show Less
                    </Button>
                ) : (
                    <Button variant="outline-secondary" size="sm" onClick={() => setExpanded(true)}>
                        Show {comments.length - defaultCommentsToShow} More
                    </Button>
                ))}
            {(expanded || !expandable) && (
                <Button variant="outline-primary" size="sm" onClick={onAddCommentClick}>
                    Add Comment
                </Button>
            )}
        </>
    );
}

function AddOptionDialog({
    event,
    roundId,
    onDismiss,
}: {
    event: MoraleEvent;
    roundId: number;
    onDismiss: () => void;
}): JSX.Element {
    const [valid, setValid] = useState(false);
    const [busy, setBusy] = useState(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const nameInput = useRef<typeof FormControl & HTMLInputElement>(null);
    useLayoutEffect(() => void nameInput.current?.focus(), []);

    const isValid = (): boolean => !!(!busy && nameInput.current?.value);
    const updateValid = (): void => {
        if (isValid() !== valid) {
            setValid(!valid);
        }
    };

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss()}>
            <Modal.Header>
                <Modal.Title>Add Option</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors && (
                    <Alert variant="danger">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Alert>
                )}
                <Form
                    onInput={updateValid}
                    onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            onSaveClick();
                        }
                    }}
                >
                    <Form.Group controlId="optionName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" maxLength={50} ref={nameInput} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!valid} onClick={onSaveClick}>
                    Add Option
                </Button>
                <Button variant="secondary" disabled={busy} onClick={(): void => onDismiss()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onSaveClick(): Promise<void> {
        setBusy(true);
        setErrors(null);
        const option: NewOption = {
            name: nameInput.current!.value,
        };
        const errors = await addOption(event.eventId, roundId, option);
        setBusy(false);
        if (errors) {
            setErrors(errors);
        } else {
            onDismiss();
        }
    }
}

function ConfirmDeleteDialog({
    option,
    onDismiss,
}: {
    option: Option;
    onDismiss: (confirmed: boolean) => void;
}): JSX.Element {
    return (
        <ConfirmationDialog primaryButtonText="Delete" onDismiss={onDismiss}>
            Are you sure you want to delete the option {option.name}?
        </ConfirmationDialog>
    );
}

function AddCommentDialog({
    event,
    roundId,
    optionId,
    onDismiss,
}: {
    event: MoraleEvent;
    roundId: number;
    optionId: number;
    onDismiss: () => void;
}): JSX.Element {
    const [valid, setValid] = useState(false);
    const [busy, setBusy] = useState(false);
    const [renderedComment, setRenderedComment] = useState({ __html: "" });
    const [errors, setErrors] = useState<string[] | null>(null);
    const commentInput = useRef<typeof FormControl & HTMLTextAreaElement>(null);
    useLayoutEffect(() => void commentInput.current?.focus(), []);

    const isValid = (): boolean => !!(!busy && commentInput.current?.value);
    const updateValid = (): void => {
        if (isValid() !== valid) {
            setValid(!valid);
        }
    };

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss()}>
            <Modal.Header>
                <Modal.Title>Add Comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors && (
                    <Alert variant="danger">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Alert>
                )}
                <Form
                    onInput={updateValid}
                    onSelect={tab => {
                        setRenderedComment(renderMarkdown(commentInput.current?.value ?? ""));
                    }}
                >
                    <Form.Group controlId="optionName">
                        <Form.Label>
                            Comment (supports{" "}
                            <a
                                href="https://daringfireball.net/projects/markdown/syntax"
                                rel="noopener"
                                target="_blank"
                            >
                                Markdown
                            </a>
                            )
                        </Form.Label>
                        <Tabs>
                            <Tab eventKey="editor" title="Edit">
                                <Form.Control
                                    as="textarea"
                                    className="mt-2 comment-field"
                                    maxLength={4000}
                                    ref={commentInput}
                                />
                            </Tab>
                            <Tab eventKey="preview" title="Preview">
                                <div
                                    className="text ms-2 mt-2 comment-field"
                                    dangerouslySetInnerHTML={renderedComment}
                                />
                            </Tab>
                        </Tabs>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!valid} onClick={onSaveClick}>
                    Add Comment
                </Button>
                <Button variant="secondary" disabled={busy} onClick={(): void => onDismiss()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onSaveClick(): Promise<void> {
        setBusy(true);
        setErrors(null);
        const comment = commentInput.current!.value;
        const errors = await addComment(event.eventId, roundId, optionId, comment);
        setBusy(false);
        if (errors) {
            setErrors(errors);
        } else {
            onDismiss();
        }
    }
}

interface RoundDialogProps {
    event: MoraleEvent;
    onDismiss: () => void;
    existing?: Round;
    existingRoundId?: number;
}
export function RoundDialog({ event, onDismiss, existing, existingRoundId }: RoundDialogProps): JSX.Element {
    const editing = !!existing;
    const [valid, setValid] = useState(editing);
    const [busy, setBusy] = useState(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const [showCopyRoundDialog, setShowCopyRoundDialog] = useState(false);
    const [options, setOptions] = useState<Option[] | null>(null);
    const nameInput = useRef<typeof FormControl & HTMLInputElement>(null);
    const descriptionInput = useRef<typeof FormControl & HTMLTextAreaElement>(null);
    const votesPerUserInput = useRef<typeof FormControl & HTMLInputElement>(null);
    const addOptionsInput = useRef<typeof FormCheck & HTMLInputElement>(null);
    const hideResultsUntilVotedInput = useRef<typeof FormCheck & HTMLInputElement>(null);
    const closeTime = useRef(existing ? existing.closeTime : 0);
    useLayoutEffect(() => void nameInput.current?.focus(), []);

    const updateValid = useCallback(() => {
        const v = !!(
            !busy &&
            nameInput.current?.value &&
            closeTime.current > Date.now() &&
            Number(votesPerUserInput.current?.value) > 0
        );
        if (v !== valid) {
            setValid(v);
        }
    }, [busy, valid]);

    return (
        <Modal backdrop="static" show={true} onHide={(): void => onDismiss()}>
            <Modal.Header>
                <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between">
                        {editing ? "Edit" : "Add"} Round{" "}
                        {!existing && <Button onClick={() => setShowCopyRoundDialog(true)}>Copy Previous Round</Button>}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors && (
                    <Alert variant="danger">
                        {errors.map((e, i) => (
                            <div key={i}>{e}</div>
                        ))}
                    </Alert>
                )}
                <Form
                    onInput={updateValid}
                    onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                        if (e.key === "Enter" && valid && (e.target as HTMLElement).nodeName !== "TEXTAREA") {
                            e.preventDefault();
                            onSaveClick();
                        }
                    }}
                >
                    <Form.Group controlId="roundName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" defaultValue={existing?.name} maxLength={50} ref={nameInput} />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            defaultValue={existing?.description}
                            maxLength={4000}
                            ref={descriptionInput}
                        />
                    </Form.Group>
                    <Form.Group controlId="eventDateTime">
                        <Form.Label>Closing Time</Form.Label>
                        <DateTime
                            isValidDate={(d: Moment) => d.isAfter(moment().subtract(1, "day"))}
                            initialValue={existing && new Date(existing.closeTime)}
                            onChange={v => {
                                closeTime.current = typeof v === "string" ? 0 : v.valueOf();
                                updateValid();
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="votesPerUser">
                        <Form.Label>Votes per user</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={existing?.votesPerUser}
                            maxLength={2}
                            pattern="[0-9]+"
                            ref={votesPerUserInput}
                        />
                    </Form.Group>
                    <Form.Group controlId="usersCanAddOptions">
                        <Form.Check
                            ref={addOptionsInput}
                            defaultChecked={existing?.usersCanAddOptions}
                            label="User can add options"
                        />
                    </Form.Group>
                    <Form.Group controlId="hideResultsUntilVoted">
                        <Form.Check
                            ref={hideResultsUntilVotedInput}
                            defaultChecked={existing?.hideResultsUntilVoted}
                            label="Hide results until user has voted"
                        />
                    </Form.Group>
                    {options && options.length && (
                        <div>
                            Includes {options.length} copied options.{" "}
                            <Button onClick={() => setOptions(null)} size="sm">
                                Remove
                            </Button>
                        </div>
                    )}
                </Form>
                {showCopyRoundDialog && <CopyRoundDialog teamId={event.teamId} onDismiss={onCopyRound} />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!valid} onClick={onSaveClick}>
                    {editing ? "Edit" : "Add"} Round
                </Button>
                <Button variant="secondary" disabled={busy} onClick={(): void => onDismiss()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onSaveClick(): Promise<void> {
        setBusy(true);
        setErrors(null);
        const round: EditRound = {
            name: nameInput.current!.value,
            description: descriptionInput.current!.value,
            closeTime: closeTime.current,
            votesPerUser: Number(votesPerUserInput.current!.value),
            usersCanAddOptions: addOptionsInput.current!.checked,
            hideResultsUntilVoted: hideResultsUntilVotedInput.current!.checked,
        };
        if (options && options.length) {
            round.options = options;
        }
        let errors: string[] | undefined;
        if (existing && existingRoundId !== undefined) {
            errors = await editRound(event.eventId, existingRoundId, round);
        } else {
            errors = await addRound(event.eventId, round);
        }
        setBusy(false);
        if (errors) {
            setErrors(errors);
        } else {
            onDismiss();
        }
    }

    function onCopyRound(result: { round: Round; includeOptions: boolean } | null): void {
        if (result) {
            const { round, includeOptions } = result;
            nameInput.current!.value = round.name;
            descriptionInput.current!.value = round.description;
            votesPerUserInput.current!.value = String(round.votesPerUser);
            addOptionsInput.current!.checked = round.usersCanAddOptions;
            hideResultsUntilVotedInput.current!.checked = !!round.hideResultsUntilVoted;
            if (includeOptions) {
                setOptions(round.options.map(o => ({ name: o.name, votes: [], comments: [] })));
            }
        }
        setShowCopyRoundDialog(false);
    }
}

interface AugmentedOption extends Option {
    optionId: number;
    order: number;
}

function augmentOptions(options: Option[], seed: number): AugmentedOption[] {
    const rnd = mulberry32(seed);
    return options.map((o, optionId) => ({ ...o, optionId, order: rnd() }));
}

function CopyOptionsDialog({
    errorCollection,
    onDismiss,
    targetEvent,
    targetRoundId,
    team,
}: {
    errorCollection: ErrorCollection;
    onDismiss: () => void;
    targetEvent: MoraleEvent;
    targetRoundId: number;
    team: Team;
}): JSX.Element {
    const [eventsObservable] = useState(() => {
        const observable = new Observable<MoraleEvent[] | null>(null);
        getTeamEvents(team.teamId).then(events => eventsObservable.setValue(events));
        return observable;
    });
    const [activeEvent, setActiveEvent] = useState<MoraleEvent | null>(null);
    const [activeRound, setActiveRound] = useState<Round | null>(null);
    const [activeOptions, setActiveOptions] = useState(() => new Set<number>());
    const [busy, setBusy] = useState(false);

    return (
        <Modal show size="xl">
            <Modal.Header>
                <Modal.Title>Copy Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            Events
                            <Observer observable={eventsObservable}>
                                {events =>
                                    events && (
                                        <ListGroup>
                                            {events.map(e => (
                                                <ListGroup.Item
                                                    key={e.eventId}
                                                    active={activeEvent === e}
                                                    onClick={() => {
                                                        setActiveOptions(new Set());
                                                        setActiveRound(null);
                                                        setActiveEvent(e);
                                                    }}
                                                >
                                                    {e.name}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )
                                }
                            </Observer>
                        </Col>
                        <Col>
                            Rounds
                            {activeEvent && (
                                <ListGroup>
                                    {activeEvent.rounds.map((r, i) => (
                                        <ListGroup.Item
                                            key={i}
                                            active={activeRound === r}
                                            onClick={() => {
                                                setActiveRound(r);
                                                setActiveOptions(new Set(r.options.keys()));
                                            }}
                                        >
                                            {r.name}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </Col>
                        <Col>
                            Options
                            {activeRound && (
                                <ListGroup>
                                    {activeRound.options
                                        .sort((a, b) => b.votes.length - a.votes.length)
                                        .map((o, i) => (
                                            <ListGroup.Item
                                                key={i}
                                                active={activeOptions.has(i)}
                                                onClick={() => {
                                                    setActiveOptions(
                                                        activeOptions.has(i)
                                                            ? (activeOptions.delete(i), new Set(activeOptions))
                                                            : new Set(activeOptions.add(i))
                                                    );
                                                }}
                                            >
                                                {o.name}
                                                <span className="fs-6"> ({o.votes.length} votes)</span>
                                            </ListGroup.Item>
                                        ))}
                                </ListGroup>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    disabled={!(activeEvent && activeRound && activeOptions.size > 0) || busy}
                    onClick={onCopyClick}
                >
                    Copy Options
                </Button>
                <Button variant="secondary" onClick={(): void => onDismiss()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onCopyClick(): Promise<void> {
        try {
            setBusy(true);
            if (!activeRound) {
                return;
            }
            for (let i = 0; i < activeRound.options.length; i++) {
                if (activeOptions.has(i)) {
                    const o = activeRound.options[i];
                    const result = await addOption(targetEvent.eventId, targetRoundId, o);
                    if (result) {
                        for (const r of result) {
                            errorCollection.push(`Error copying option ${o.name}: ${r}`);
                        }
                    }
                }
            }
            onDismiss();
        } finally {
            setBusy(false);
        }
    }
}

function CopyRoundDialog({
    onDismiss,
    teamId,
}: {
    onDismiss: (result: { round: Round; includeOptions: boolean } | null) => void;
    teamId: number;
}): JSX.Element {
    const [eventsObservable] = useState(() => {
        const observable = new Observable<MoraleEvent[] | null>(null);
        getTeamEvents(teamId).then(events => eventsObservable.setValue(events));
        return observable;
    });
    const [activeEvent, setActiveEvent] = useState<MoraleEvent | null>(null);
    const [activeRound, setActiveRound] = useState<Round | null>(null);
    const [includeOptions, setIncludeOptions] = useState(true);

    return (
        <Modal show size="xl">
            <Modal.Header>
                <Modal.Title>Copy Round</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            Events
                            <Observer observable={eventsObservable}>
                                {events =>
                                    events && (
                                        <ListGroup>
                                            {events.map(e => (
                                                <ListGroup.Item
                                                    key={e.eventId}
                                                    active={activeEvent === e}
                                                    onClick={() => {
                                                        setActiveRound(null);
                                                        setActiveEvent(e);
                                                    }}
                                                >
                                                    {e.name}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )
                                }
                            </Observer>
                        </Col>
                        <Col>
                            Rounds
                            {activeEvent && (
                                <ListGroup>
                                    {activeEvent.rounds.map((r, i) => (
                                        <ListGroup.Item
                                            key={i}
                                            active={activeRound === r}
                                            onClick={() => {
                                                setActiveRound(r);
                                            }}
                                        >
                                            {r.name}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="includeOptions">
                                <Form.Check
                                    checked={includeOptions}
                                    onChange={() => setIncludeOptions(v => !v)}
                                    label="Include options"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!(activeEvent && activeRound)} onClick={onCopyClick}>
                    Copy Round
                </Button>
                <Button variant="secondary" onClick={(): void => onDismiss(null)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

    async function onCopyClick(): Promise<void> {
        if (!activeRound) {
            return;
        }
        onDismiss({ round: activeRound, includeOptions });
    }
}
