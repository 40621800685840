import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {createRoot} from "react-dom/client";

import { getContext, logout } from "./api/user";
import { MeContext } from "./common/mecontext";
import { Root } from "./pages/root";

async function go(): Promise<void> {
    const context = await getContext();
    const container = document.getElementById("root");

    let content: JSX.Element | undefined;

    if (context === null) {
        const cookies = parseCookies();
        const loginUrl = `/login?r=${encodeURIComponent(document.location.pathname)}`;

        if (cookies["PreviouslyLoggedIn"]) {
            document.cookie = "PreviouslyLoggedIn=;path=/;max-age=0";
            window.location.href = loginUrl;
        }
        content = (
            <div>
                You&apos;re going to need to log in with GitHub to access morale.events.
                <br />
                <a href={loginUrl}>Continue</a>
            </div>
        );
    } else if (context.currentUser.status !== "approved") {
        content = (
            <div>
                Your account has not yet been approved to access morale.events. Kindly remind an administrator to
                approve your account.
                <br />
                <button
                    onClick={async (): Promise<void> => {
                        (await logout()) && (window.location.href = "/");
                    }}
                >
                    Log Out
                </button>
            </div>
        );
    }

    const root = createRoot(container!);
    if (content) {
        root.render(content);
    } else {
        root.render(
            <MeContext.Provider value={context!}>
                <Root />
            </MeContext.Provider>
        );
    }
}

go();

function parseCookies(): { [key: string]: string } {
    const cookieStrs = document.cookie.split(";");
    const cookies: { [key: string]: string } = {};
    cookieStrs.forEach(c => {
        const kvp = c.split("=");
        cookies[kvp[0].trim()] = kvp[1];
    });
    return cookies;
}
