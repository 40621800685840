import React, { ReactElement, useEffect, useRef, useState } from "react";

import { IObservable } from "../common/observable";
import { useRenderLog } from "../common/util";

export interface ObserverProps<T> {
    observable: IObservable<T>;
    children: (v: T) => ReactElement | null;
}

export function Observer<T>({ observable, children }: ObserverProps<T>): ReactElement | null {
    const setValueTimeout = useRef(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const instanceName = useRenderLog("Observer");
    const prevObservable = useRef<IObservable<T>>();
    const unsubscriber = useRef<() => void>();
    const [value, setValue] = useState(subscribe);
    if (prevObservable.current !== observable) {
        setValue(subscribe());
    }

    useEffect(() => unsubscribe, []);
    return children(value);

    function subscribe(): T {
        unsubscribe();
        prevObservable.current = observable;
        unsubscriber.current = observable.subscribe(v => {
            // console.log(`${instanceName} got %O`, v);
            // delay to avoid recursive updates in react because that makes it sad
            if (setValueTimeout.current) {
                window.clearTimeout(setValueTimeout.current);
            }
            setValueTimeout.current = window.setTimeout(() => {
                setValueTimeout.current = 0;
                setValue(v);
            }, 0);
        });
        const value = observable.getValue();
        // console.log(`${instanceName} set to %O`, value);
        return value;
    }
    function unsubscribe(): void {
        if (setValueTimeout.current) {
            window.clearTimeout(setValueTimeout.current);
            setValueTimeout.current = 0;
        }
        if (unsubscriber.current) {
            unsubscriber.current();
        }
    }
}
