import { isSupported, sanitize } from "dompurify";
import { marked } from "marked";
import { useRef, useState } from "react";
import { NavigateFunction } from "react-router";

export function errorToString(error: any): string {
    let message = error.toString();
    if (typeof error === "string") {
        message = error;
    } else if (error.message) {
        message = error.message;
    }
    return message;
}

export const pluralize = (count: number, singular: string, plural: string): string => (count === 1 ? singular : plural);

export function iif<T>(val: T): T | undefined {
    return val ? val : undefined;
}

export function css(...classes: (string | Falsey)[]): string {
    return classes.filter(c => !!c).join(" ");
}

export type SimpleMouseEvent = Pick<
    MouseEvent,
    "currentTarget" | "target" | "button" | "ctrlKey" | "metaKey" | "preventDefault"
>;
export function clickToReactRouter(e: SimpleMouseEvent, navigate: NavigateFunction): void {
    const path = (e.target as HTMLAnchorElement).getAttribute("href");
    // only respond to unmodified left button clicks
    if (!path || e.button !== 0 || e.ctrlKey || e.metaKey) {
        return;
    }
    e.preventDefault();
    navigate(path);
}

const componentCounts: { [name: string]: number } = {};
export function useRenderLog(componentName: string): string {
    const [i] = useState(() => {
        if (componentCounts[componentName] === undefined) {
            return (componentCounts[componentName] = 1);
        } else {
            return ++componentCounts[componentName];
        }
    });
    const instanceName = `${componentName}(${i})`;
    const renderCount = useRef(0);
    renderCount.current++;
    // useEffect(() => (() => console.log(`Removing ${instanceName}`)), []);
    // console.log(`Rendering ${instanceName} (${renderCount.current})`);
    return instanceName;
}

// willfully incomplete because I looked up all the falsey values and got sad
export type Falsey = undefined | null | false | "";

export function renderMarkdown(s: string): { __html: string } {
    return {
        __html: isSupported
            ? sanitize(marked(s, { silent: true }), {
                  FORBID_TAGS: ["img"],
              })
            : "DOMPurify is not supported on this platform.",
    };
}

export function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
}

/**
 * Returns a function that returns a series of random numbers, seeded with a.
 */
export function mulberry32(a: number) {
    return function () {
        let t = (a += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
}

export function rateLimit<T extends (...args: Parameters<T>) => any>(
    func: T,
    limit: number,
    resetTime: number
): (this: ThisParameterType<T>, ...args: Parameters<T>) => ReturnType<T> {
    let lastCall = 0;
    let lastReset = 0;
    let count = 0;
    return function (...args: Parameters<T>): ReturnType<T> {
        const now = Date.now();
        if (now - lastReset > resetTime) {
            count = 0;
            lastReset = now;
        }
        if (count >= limit) {
            throw new Error("Rate limit exceeded");
        }
        if (now - lastCall > resetTime) {
            count = 0;
        }
        lastCall = now;
        count++;
        return func.apply(this, args);
    };
}
